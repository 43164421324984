@font-face {
  font-family: "IBMPlexSans-Bold";
  src: local("Bold"), url("./Font/IBMPlexSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "IBMPlexSans-Medium";
  src: local("Medium"), url("./Font/IBMPlexSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "IBMPlexSans-Regular";
  src: local("Regular"),
    url("./Font/IBMPlexSans-Regular.ttf") format("truetype");
}

.btn-primary:hover{
  background-color: #888888 !important;
}

.my-button:hover{
  background-color: #888888 !important;
}