.radial {
  background: radial-gradient(
      38.5% 48.25% at 72.15% 46.76%,
      #ff9d96 0%,
      #978dec 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.carousel-indicators {
  margin-bottom: -3rem !important;
}
.carousel-control-next {
  display: none !important;
}
.carousel-control-prev {
  display: none !important;
}

@media (max-width: 500px) {
  .resResrcTitle {
    font-size: 32px !important;
    text-align: start !important;
  }
}
.respicTitle {
  font-size: 25px !important;
}
.slideWidth {
  width: 90% !important;
}

@media (max-width: 990px) {
  .resHeight {
    height: 500px !important;
  }
}
@media (min-width: 991px) {
  .resHeight {
    height: 900px !important;
  }
}
