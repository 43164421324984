@media (max-width: 766px) {
  .resfooterLogo {
    text-align: center !important;
  }
}

@media (max-width: 766px) {
  .hipaaresp {
    justify-content: center !important;
  }
}
@media (min-width: 767px) {
  .hipaaresp {
    justify-content: end !important;
  }
}
@media (max-width: 766px) {
  .mrTop {
    margin-top: 25px;
  }
}
