@media (min-width: 991px) {
  .navbar {
    padding-top: 0rem;
    padding-bottom: 0rem;
    /* margin-bottom: -110px; */
  }
}
.defaultheader .nav-link {
  color: rgb(0, 0, 0) !important;
}

.defaultheader {
  background-color: white !important;
  padding-top: "100px" !important;
}
.changeheader {
  background-color: white !important;
  padding-top: "100px" !important;
}
.logo {
  max-width: 100% !important;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
  /* padding-top: 20px !important; */
}

@media (max-width: 991px) {
  .navbar-collapse {
    padding-top: 20px;
    line-height: 40px;
  }
}

#navbarScroll .navbar-nav .nav-link {
  display: flex;
  align-items: center;
  min-width: 100px;
  position: relative;
}
.navbar-light .navbar-toggler {
  background-color: white;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
.navbar-light .navbar-toggler {
  background-color: white;
  float: right !important;
}
@media (max-width: 974px) {
  .navbar-nav {
    position: absolute;
    background-color: white;
    /* padding-right: 700px; */
    padding-bottom: 80px;
    width: -webkit-fill-available;
    margin-top: -66px;

    border-bottom-left-radius: 35vh;
    box-shadow: 15px 26px 100px rgba(0, 0, 0, 0.32);
    left: 0;
    padding-top: 45px;
  }
}
.navbar-toggler {
  border: none !important;
}
.hamburger-react {
  z-index: 99999999999999999 !important;
}
.active:hover {
  color: #8362aa !important;
  border-bottom: 2px solid #8362aa !important;
  padding-bottom: 5px !important;
}
.active {
  border-bottom: 2px solid #8462aa00 !important;
  padding-bottom: 5px !important;
}

.active-now {
  color: #8362aa !important;
  border-bottom: 2px solid #8362aa !important;
  padding-bottom: 5px !important;
} 

button:hover {
  background: #8362aa !important;
  border: #8362aa !important;
}
@media (max-width: 974px) {
  .menuColumn {
    padding-top: 13px !important;
  }
}
@media (min-width: 975px) {
  .menuColumn {
    padding-top: 23px !important;
  }
}
