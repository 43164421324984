.rightlign {
  background-color: #8362aa;
  width: 5px;
  height: 833px !important;
  /* margin-bottom: -3px; */
  padding: 89;
  padding-top: 40px;
  position: absolute;
  margin-top: 95px;
  margin-right: -70px;
}
.pinkrightlign {
  background-color: #f065a5;
  width: 5px;
  height: 820px !important;
  /* margin-bottom: -3px; */
  padding: 89;
  padding-top: 40px;
  position: absolute;
  margin-top: 100px;
  margin-right: -70px;
}
.yellowrightlign {
  background-color: #fee56c;
  width: 5px;
  height: 775px !important;
  /* margin-bottom: -3px; */
  padding: 89;
  padding-top: 40px;
  position: absolute;
  margin-top: 98px;
  margin-right: -70px;
}
.resyellowvectmob {
  position: absolute !important;
  left: 0;
  padding: 0 !important;
  max-width: 30% !important;
  z-index: -1;
  margin-top: -270px !important;
  max-width: 25% !important;
}
.respinkvectmob {
  position: absolute !important;
  right: 0;
  padding: 0 !important;
  max-width: 30% !important;
  z-index: -1;
  margin-top: -270px !important;
  max-width: 25% !important;
}
