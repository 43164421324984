@media (max-width: 400px) {
  .restabIcon {
    max-width: 45px !important;
  }
}
@media (min-width: 401px) {
  .restabIcon {
    max-width: 45px !important;
  }
}

.restabtitle {
  font-size: 1.2rem !important;
}

@media (max-width: 700px) {
  .restabtitle {
    font-size: 1rem !important;
  }
}
