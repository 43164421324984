/* .card-header {
  padding: 12px !important;
  margin-bottom: 0 !important;
  background-color: rgba(0, 0, 0, 0.03);

  background-color: white !important;

  border: 1px solid white;
} */
/* .card-header:first-child {
  border: none !important;
}
.card {
  border: none !important;
} */
.shadow {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%) !important;
}
