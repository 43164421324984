@media (max-width: 600px) {
  .resPatientsHeroPadding {
    padding: 10px !important;
  }
}

@media (max-width: 600px) {
  .respconsultitle {
    font-size: 30px !important;
    padding-top: 20px !important;
    padding-right: 1em;
  }
}
@media (max-width: 600px) {
  .respconsuldesription {
    font-size: 18px !important;
    padding-bottom: 80px;
  }
}
