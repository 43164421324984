.item {
  padding-right: 35px !important;
}
.react-multi-carousel-dot--active button {
  background-color: #ffffff !important;
  border: none !important;
  width: 50px !important;
  border-radius: 10px;
  /* height: 12px !important;
  width: 40px !important; */
  /* border-radius: 0px !important; */
  /* padding: 0px !important;
  margin: 0px !important; */
}
.react-multi-carousel-dot button {
  background-color: rgb(175, 175, 175);
  border: none !important;
  width: 20px;
  border-radius: 10px;
  /* height: 2px !important;
  width: 40px !important; */
  /* padding: 0px !important;
  margin: 0px !important; */
  /* border-radius: 0px !important; */
}
.react-multi-carousel-dot-list {
  justify-content: start !important;
  padding-left: 30px !important;
}
.carouselPadding {
  padding-right: 70px;
}
@media (max-width: 700px) {
  .carouselPadding {
    padding-right: 0px !important;
  }
}
