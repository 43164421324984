@media (max-width: 600px) {
  .resFaqyellowvect {
    position: absolute !important;
    left: 0;
    padding: 0 !important;
    max-width: 100px !important;
    z-index: -1;
    margin-top: -170px !important;
  }
}
@media (min-width: 601px) {
  .resFaqyellowvect {
    position: absolute !important;
    left: 0;
    padding: 0 !important;
    max-width: 20% !important;
    padding-top: 140px !important;
    z-index: -1;
  }
}
@media (max-width: 600px) {
  .resFaqheading {
    font-size: 32px !important;
  }
}
@media (max-width: 600px) {
  .resrightfaqvect {
    position: absolute !important;
    right: 0;

    padding: 0 !important;
    max-width: 200px !important;
    z-index: -1;
    padding-top: 100px !important;
  }
}
@media (min-width: 601px) {
  .resrightfaqvect {
    position: absolute !important;
    right: 0;

    padding: 0 !important;
    max-width: 350px !important;
    margin-top: -170px !important;
    z-index: -1;
  }
}
@media (max-width: 600px) {
  .resLeftfaqvect {
    position: absolute !important;
    left: 0;

    padding: 0 !important;
    max-width: 140px !important;
    z-index: -1;
    padding-top: 100px !important;
  }
}
@media (min-width: 601px) {
  .resLeftfaqvect {
    position: absolute !important;
    left: 0;

    padding: 0 !important;
    max-width: 140px !important;
    z-index: -1;
    padding-top: 350px !important;
  }
}
